<div class="bg">
  <div class="container">
    <!-- <img src="../../../../assets/icons/icon-arrow-down.webp" alt=""/> -->
    <app-breadcrumbs
      grandparentTitle="HOME"
      grandparentLink="/"
      parentTitle="SUSTAINABILITY"
      childTitle="OUR IMPACT"
      [isLarge]="false"
    ></app-breadcrumbs>

    <div class="hero">
      <div class="col">
        <p class="overline">Our impact</p>
        <h1>
          Environmental<br />
          and Social Impact
        </h1>
        <p>
          Fortress is dedicated to acting on climate and supporting social
          impact through its comprehensive sustainability and corporate
          citizenship initiatives. Renewable energy is a key focus, with solar
          plants reducing our carbon footprint and contributing to a cleaner
          energy grid, benefiting our business and the broader economy. This
          aligns with our green lease initiative, allowing tenants to share the
          environmental benefits. Additionally, Fortress prioritises social
          responsibility by actively supporting community-based and charitable
          organisations in our properties' areas, fostering long-term positive
          change through targeted solution initiatives.
        </p>
        <a
          class="secondary-btn"
          href="https://downloads.ctfassets.net/7unabnt9bnbr/2SRT4qWdV7PbJAXSZRqZvO/33ba04cbc8fad1cb213b5005506f931c/Fortress_integrated_report_2024.pdf#page=57"
          target="_blank"
          >View 2024 Report</a
        >
      </div>
    </div>

    <div class="mobile-hero-img">
      <img src="../../../../assets/images/sus-our-impact-hero.png" />
    </div>

    <div class="our-approach-container">
      <div class="left-content">
        <h4>Our approach</h4>
        <p>
          We are committed to operating a sustainable business aligned with the
          UN Sustainable Development Goals ("SDGs") driven by the our
          environmental goals and themes; Powering Growth and Acting on Climate.
        </p>

        <div class="tabs">
          <a
            [class.active]="activeTab === 'tab1'"
            (click)="setActiveTab('tab1')"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <path
                d="M12.5 8V16"
                stroke="#00C5CB"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.5 12H8.5"
                stroke="#00C5CB"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Powering growth
          </a>
          <a
            [class.active]="activeTab === 'tab2'"
            (click)="setActiveTab('tab2')"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <path
                d="M12.5 8V16"
                stroke="#00C5CB"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.5 12H8.5"
                stroke="#00C5CB"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Acting on climate
          </a>

          <!-- <a [class.active]="activeTab === 'tab3'" (click)="setActiveTab('tab3')">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                <path d="M12.5 8V16" stroke="#00C5CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M16.5 12H8.5" stroke="#00C5CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg> 
                            Supporting Social
                        </a> -->
        </div>

        <div class="tab-content" *ngIf="activeTab === 'tab1'" [@fadeIn]>
          <ngx-slick-carousel
            class="our-approach-carousel"
            [config]="slideConfig"
            (afterChange)="afterChange1($event)"
          >
            <div class="block" ngxSlickItem>
              <div class="content-container">
                <p class="overline">Our impact</p>
                <h3>Decent work and economic growth</h3>
                <p>
                  Through our construction pipeline we are contributing to
                  economic growth and job creation. Promoting and ensuring safe
                  and secure working environments for all workers and employees.
                </p>
              </div>
            </div>

            <div class="block" ngxSlickItem>
              <div class="content-container">
                <p class="overline">Our impact</p>
                <h3>Sustainable cities and communities</h3>
                <p>
                  We are committed to sustainable construction practices and the
                  development of climate-resilient buildings.
                </p>
              </div>
            </div>

            <div class="block" ngxSlickItem>
              <div class="content-container">
                <p class="overline">Our impact</p>
                <h3>Responsible consumption and production</h3>
                <p>
                  We are committed to the sustainable management and efficient
                  use of natural resources as well as waste reduction and
                  recycling.
                </p>
              </div>
            </div>
          </ngx-slick-carousel>
        </div>

        <div class="tab-content" *ngIf="activeTab === 'tab2'" [@fadeIn]>
          <ngx-slick-carousel
            class="our-approach-carousel slider2"
            [config]="slideConfig"
            (afterChange)="afterChange2($event)"
          >
            <div class="block" ngxSlickItem>
              <div class="content-container">
                <p class="overline">Our impact</p>
                <h3>Clean water and sanitation</h3>
                <p>
                  Operationally, we are committed to increasing our water use
                  efficiency and to protecting and restoring the wetlands at our
                  Meycol and Clairwood properties.
                </p>
              </div>
            </div>

            <div class="block" ngxSlickItem>
              <div class="content-container">
                <p class="overline">Our impact</p>
                <h3>Climate action</h3>
                <p>
                  Our partnership with FTFA allows the opportunity for improved
                  education, raising awareness and human institutional capacity
                  on climate change.
                </p>
              </div>
            </div>

            <div class="block" ngxSlickItem>
              <div class="content-container">
                <p class="overline">Our impact</p>
                <h3>Life on land</h3>
                <p>
                  The Meycol Nature Reserve presents the opportunity to be
                  actively involved in the restoration and conservation of
                  critically endangered forest swamp land on the KwaZulu-Natal
                  coastline.
                </p>
              </div>
            </div>

            <div class="block" ngxSlickItem>
              <div class="content-container">
                <p class="overline">Our impact</p>
                <h3>Affordable and clean energy</h3>
                <p>
                  We are committed to the continual roll-out of our renewable
                  energy programme therefore increasing the share of renewable
                  energy to the local energy mix.
                </p>
              </div>
            </div>
          </ngx-slick-carousel>
        </div>

        <div class="tab-content" *ngIf="activeTab === 'tab3'" [@fadeIn]>
          <ngx-slick-carousel
            class="our-approach-carousel slider3"
            [config]="slideConfig"
            (afterChange)="afterChange3($event)"
          >
            <div class="block" ngxSlickItem>
              <div class="content-container">
                <p class="overline">Our impact</p>
                <h3>No poverty</h3>
                <p>
                  Our supplier and enterprise development programmes help
                  sustain existing jobs and create new employment opportunities.
                </p>
              </div>
            </div>

            <div class="block" ngxSlickItem>
              <div class="content-container">
                <p class="overline">Our impact</p>
                <h3>Zero hunger</h3>
                <p>
                  Through our partnership with the FTFA, we help schools and
                  communities with skills transfers and empowerment, as well as
                  immediate short-term nutritional needs.
                </p>
              </div>
            </div>

            <div class="block" ngxSlickItem>
              <div class="content-container">
                <p class="overline">Our impact</p>
                <h3>Quality education</h3>
                <p>
                  We fund various educational initiatives, from early childhood
                  development to bursaries for tertiary education. We are also
                  the lead sponsor of the Anchor Stockbrokers internship
                  programme, with interns on rotation at Broll.
                </p>
              </div>
            </div>

            <div class="block" ngxSlickItem>
              <div class="content-container">
                <p class="overline">Our impact</p>
                <h3>Gender equality</h3>
                <p>
                  We have a number of women-owned businesses in our supplier and
                  enterprise development programmes. We also provide space for
                  two charities that support and assist abused women and their
                  children. We also fund a human capital support initiative.
                </p>
              </div>
            </div>

            <div class="block" ngxSlickItem>
              <div class="content-container">
                <p class="overline">Our impact</p>
                <h3>Decent work and economic growth</h3>
                <p>
                  Our supplier and enterprise development programmes contribute
                  to wider changes including contribution to the GDP,
                  employment, taxes and household income.
                </p>
              </div>
            </div>

            <div class="block" ngxSlickItem>
              <div class="content-container">
                <p class="overline">Our impact</p>
                <h3>Responsible consumption and production</h3>
                <p>
                  To reduce food waste, we are working with an independent
                  company that sources, collects and redistributes edible
                  surplus food. We partner on initiatives that facilitate
                  recycling and clean-up operations.
                </p>
              </div>
            </div>

            <div class="block" ngxSlickItem>
              <div class="content-container">
                <p class="overline">Our impact</p>
                <h3>Climate action</h3>
                <p>All our initiatives foster awareness of the environment.</p>
              </div>
            </div>
          </ngx-slick-carousel>
        </div>
      </div>

      <div class="right-content">
        <img
          [src]="slide1IconImages[activeSlide1Index]"
          *ngIf="activeTab === 'tab1'"
          class="sus-icon"
        />
        <img
          [src]="slide2IconImages[activeSlide2Index]"
          *ngIf="activeTab === 'tab2'"
          class="sus-icon"
        />
        <img
          [src]="slide3IconImages[activeSlide3Index]"
          *ngIf="activeTab === 'tab3'"
          class="sus-icon"
        />
        <img
          src="../../../../assets/images/our-approach-carousel-img.png"
          class="background-img"
        />
      </div>
    </div>

    <!-- <div class="our-partners-container">

                <h4>Our partners</h4>

                <div class="partner-logos">

                    <img src="../../../../assets/images/partner-logos/image3.png" />
                    <img src="../../../../assets/images/partner-logos/image5.png" />
                    <img src="../../../../assets/images/partner-logos/image8.png" />
                    <img src="../../../../assets/images/partner-logos/image9.png" />
                    <img src="../../../../assets/images/partner-logos/image10.png" />
                    <img src="../../../../assets/images/partner-logos/image6.png" />
                    <img src="../../../../assets/images/partner-logos/image2.png" />
                    <img src="../../../../assets/images/partner-logos/image7.png" />
                    <img src="../../../../assets/images/partner-logos/image4.png" />

                </div>

                <div class="partner-logos mobile">

                    <ngx-slick-carousel class="our-commitment-carousel" [config]="slideConfigMobile" (afterChange)="afterChange($event)">
                        <div ngxSlickItem>
                            <img src="../../../../assets/images/partner-logos/image3.png" />
                            <img src="../../../../assets/images/partner-logos/image5.png" />
                        </div>
                        <div ngxSlickItem>
                            <img src="../../../../assets/images/partner-logos/image8.png" />
                            <img src="../../../../assets/images/partner-logos/image9.png" />
                        </div>
                        <div ngxSlickItem>
                            <img src="../../../../assets/images/partner-logos/image10.png" />
                            <img src="../../../../assets/images/partner-logos/image6.png" />
                        </div>
                        <div ngxSlickItem>
                            <img src="../../../../assets/images/partner-logos/image2.png" />
                            <img src="../../../../assets/images/partner-logos/image7.png" />
                        </div>
                        <div ngxSlickItem>
                            <img src="../../../../assets/images/partner-logos/image4.png" />
                        </div>
                    </ngx-slick-carousel>

                </div>

            </div> -->

    <div class="our-commitment-container">
      <div class="inner">
        <div class="left-content">
          <h4>Our commitment</h4>
          <p>
            Our commitment to manage and reduce the impact our operations have
            on the environment, most notably, our commitment to fighting climate
            change and the restriction of global temperature rise to below
            1,5&deg;C
          </p>
          <ul>
            To achieving that vision we:
            <li>Climate change adaption and strategy</li>
            <li>Improving Energy and water efficiency</li>
            <li>Renewable energy and roll-out our new developments</li>
            <li>Biodiversity</li>
          </ul>
        </div>

        <div class="right-content">
          <div class="row-headings">
            <p>Target</p>
            <p>Progress</p>
          </div>

          <div class="card-container">
            <div class="card">
              <h5>Carbon</h5>
              <div class="cols">
                <div>
                  <p>
                    We are committed to the continual reduction of our carbon
                    footprint both in absolute terms and intensity.
                  </p>
                </div>
                <div>
                  <p>
                    27% reduction in portfolio emissions and 26% reduction in
                    intensity since 2018.
                  </p>
                </div>
              </div>
            </div>

            <div class="card">
              <h5>Energy</h5>
              <div class="cols">
                <div>
                  <p>
                    We are committed to the continual reduction of energy
                    intensity and consumption in absolute terms.
                  </p>
                </div>
                <div>
                  <p>
                    We have achieved a 30% reduction in portfolio consumption
                    and a 29% reduction in energy intensity.
                  </p>
                </div>
              </div>
            </div>

            <div class="card">
              <h5>Water</h5>
              <div class="cols">
                <div>
                  <p>
                    We are committed to the continual reduction of water
                    intensity and consumption in absolute terms.
                  </p>
                </div>
                <div>
                  <p>
                    We have achieved a 22% reduction in portfolio consumption
                    and a 21% reduction in water intensity.
                  </p>
                </div>
              </div>
            </div>

            <div class="card">
              <h5>Waste</h5>
              <div class="cols">
                <div>
                  <p>
                    We aimed to increase our retail portfolio's recycling
                    volumes to 70%
                  </p>
                </div>
                <div>
                  <p>
                    We are achieving a 60% recycling ratio. We have achieved 86%
                    of the target.
                  </p>
                </div>
              </div>
            </div>

            <div class="card">
              <h5>Renewable energy</h5>
              <div class="cols">
                <div>
                  <p>
                    Our main focus has been in solar energy instalments target
                    of 12,835MW by 2025
                  </p>
                </div>
                <div>
                  <p>
                    As of 2023 we have installed 10,233MW reaching 80% 2025
                    target.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <ngx-slick-carousel
            class="our-commitment-carousel"
            [config]="slideConfigMobile"
            (afterChange)="afterChange($event)"
          >
            <div class="card-mobile" ngxSlickItem>
              <h5>Carbon</h5>
              <div class="cols">
                <div>
                  <p class="mobile-heading">Target</p>
                  <p>
                    We are committed to the continual reduction of our carbon
                    footprint both in absolute terms and intensity.
                  </p>
                </div>
                <div>
                  <p class="mobile-heading">Progress</p>
                  <p>
                    18% reduction in portfolio emissions and 18% reduction in
                    intensity since 2018.
                  </p>
                </div>
              </div>
            </div>

            <div class="card-mobile" ngxSlickItem>
              <h5>Energy</h5>
              <div class="cols">
                <div>
                  <p class="mobile-heading">Target</p>
                  <p>
                    We are committed to the continual reduction of energy
                    intensity and consumption in absolute terms.
                  </p>
                </div>
                <div>
                  <p class="mobile-heading">Progress</p>
                  <p>
                    We have achieved a 23% reduction in portfolio consumption
                    and a 23% reduction in energy intensity.
                  </p>
                </div>
              </div>
            </div>

            <div class="card-mobile" ngxSlickItem>
              <h5>Water</h5>
              <div class="cols">
                <div>
                  <p class="mobile-heading">Target</p>
                  <p>
                    We are committed to the continual reduction of water
                    intensity and consumption in absolute terms.
                  </p>
                </div>
                <div>
                  <p class="mobile-heading">Progress</p>
                  <p>
                    We have achieved a 23% reduction in portfolio consumption
                    and a 23% reduction in water intensity
                  </p>
                </div>
              </div>
            </div>

            <div class="card-mobile" ngxSlickItem>
              <h5>Waste</h5>
              <div class="cols">
                <div>
                  <p class="mobile-heading">Target</p>
                  <p>
                    We aimed to increase our retail portfolio's recycling
                    volumes to 70%
                  </p>
                </div>
                <div>
                  <p class="mobile-heading">Progress</p>
                  <p>
                    We are achieving a 66% recycling ratio. We have achieved 94%
                    of the target.
                  </p>
                </div>
              </div>
            </div>

            <div class="card-mobile" ngxSlickItem>
              <h5>Renewable energy</h5>
              <div class="cols">
                <div>
                  <p class="mobile-heading">Target</p>
                  <p>
                    Our main focus has been in solar energy instalments target
                    of 12,835MW by 2025
                  </p>
                </div>
                <div>
                  <p class="mobile-heading">Progress</p>
                  <p>
                    As of 2022 we have installed 7,245MW reaching 91% 2022
                    target
                  </p>
                </div>
              </div>
            </div>
          </ngx-slick-carousel>
        </div>
      </div>
    </div>

    <div class="facts-container">
      <div class="row">
        <div class="col">
          <div class="card">
            <h6>12 %</h6>
            <p>Reduction in Greehouse Gas emissions from 2022</p>
          </div>
          <div class="copy">
            <h4>Carbon</h4>
            <p>
              We see the reduction of our carbon footprint as a material issue
              in our commitment to keeping global warming below 1,5. Celsius.
              Improving energy intensities and driving down consumption are
              vital elements of our carbon footprint reduction commitment.
            </p>
          </div>
        </div>

        <div class="col">
          <div class="card">
            <h6>30 %</h6>
            <p>reduction in total energy consumption since 2018</p>
          </div>
          <div class="copy">
            <h4>Energy</h4>
            <p>
              Energy efficiency has been identified as one of the key themes
              under our ‘acting on climate’ goal. We believe the pathway to
              energy efficiency lies in accurate measurement and active
              management which will lead to greater efficiencies and ultimately
              reductions in consumption. Our energy management strategy focuses
              on the provision of energy security through generator
              installations, installation of smart meters and solar PV and the
              assessment of suitability for future battery installations.
            </p>
          </div>
        </div>

        <div class="col">
          <div class="card">
            <h6>18 %</h6>
            <p>reduction in water consumption since 2018</p>
          </div>
          <div class="copy">
            <h4>Water</h4>
            <p>
              As with energy, we believe the pathway to water efficiency lies in
              accurate measurement and active management of consumption. The
              roll-out of our smart meter programme and development of our
              utility management dashboard is ongoing. These initiatives, due to
              early leak detection, will improve the quality of data and billing
              and higher levels of transparency in utility management. We also
              introduced xeriscaping and drought-tolerant/water-wise plant
              species.
            </p>
          </div>
        </div>

        <div class="col">
          <div class="card">
            <h6>27 %</h6>
            <p>reduction in waste generated from 2022</p>
          </div>
          <div class="copy">
            <h4>Waste</h4>
            <p>
              We are committed to reducing the waste stream from our operations
              and developments wherever possible. We have waste management and
              collection contracts in place at all of our properties and we are
              actively gathering data from our retail centres where we see the
              highest quantities of waste streams. We implemented an online
              waste management dashboard system in 2019 across our retail
              portfolio.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="renewable-energy-container">
      <div class="banner-img">
        <img src="../../../../assets/images/spekboom-img.png" />
        <span class="img-text"
          >Use of indigenous water-wise plants at Weskus Mall in Vredenburg. 815
          society garlic, 960 Natal plumb, 2 114 saltbush and 3 225 spekboom
          plants.</span
        >
      </div>

      <div class="inner">
        <div class="col">
          <h4>Renewable energy</h4>
          <p>
            Renewable energy presents a major climate-related opportunity for
            Fortress and a key theme under our ‘acting on climate’ goal as it
            actively reduces our carbon footprint. It is also a shared value
            initiative as solar plants not only have a direct positive impact on
            our business but also on the wider economy in adding energy to the
            grid, thereby reducing reliance on Eskom, and it has enabled us to
            share some of the benefits with our tenants through our green lease
            initiative.
          </p>

          <div class="stats-container">
            <div class="stat">
              <p class="large-number">31</p>
              <p class="text">Operational solar plants</p>
            </div>
            <div class="stat mobile-order">
              <p class="large-number">11 970 MWh</p>
              <p class="text">renewable energy generated in 2023</p>
            </div>
            <div class="stat">
              <p class="large-number">22 %</p>
              <p class="text">Increase from 2022</p>
            </div>
            <div class="stat">
              <p class="large-number">12,45 tonnes</p>
              <p class="text">
                C02 saved as a result of clean energy generated by solar plants.
              </p>
            </div>
          </div>

          <div class="stats-container mobile">
            <div class="stat">
              <p class="large-number">18 x</p>
              <p class="text">Operational</p>
            </div>
            <div class="stat">
              <p class="large-number">229 %</p>
              <p class="text">Increase since 2020</p>
            </div>
            <div class="stat full">
              <p class="large-number">10 773 MWh</p>
              <p class="text">renewable energy generated in 2022</p>
            </div>
            <div class="stat full">
              <p class="large-number">3</p>
              <p class="text">
                Shopping centres with electric vehicle charge points ensuring
                our commitment reducing our carbon footprint. We will expand on
                this initiative when the uptake allows for it.
              </p>
            </div>
          </div>
        </div>
        <div class="col">
          <img src="../../../../assets/images/renew-energy-img.png" />
        </div>
      </div>
    </div>

    <!-- <div class="solar-growth-container">
                <div class="col">
                    <h4>Solar portfolio growth</h4>
                    <p>Overview about the development, commitment and planned growth</p>
                    <p>Lorem ipsum dolor sit amet consectetur. Tristique vulputate cursus metus ut. Nunc enim laoreet pharetra hendrerit consequat lacus gravida velit. Diam volutpat nulla commodo arcu tempor luctus lorem nisi ultricies. Nunc eu proin maecenas interdum purus.</p>
                    <div class="legend">
                        <p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                <circle cx="18" cy="18" r="16" fill="url(#paint0_radial_99_15879)" stroke="#086E94" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                <defs>
                                <radialGradient id="paint0_radial_99_15879" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(18 18) rotate(115.115) scale(17.6706 15.2369)">
                                <stop offset="0.0001" stop-color="#398BA9" stop-opacity="0.5"/>
                                <stop offset="0.0002" stop-color="#398BA9" stop-opacity="0"/>
                                <stop offset="1" stop-color="#398BA9" stop-opacity="0.4"/>
                                </radialGradient>
                                </defs>
                            </svg>
                            Current solar portifolio
                        </p>
                        <p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                <circle cx="18" cy="18" r="16" fill="url(#paint0_radial_99_15882)" stroke="#086E94" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="10 10"/>
                                <defs>
                                <radialGradient id="paint0_radial_99_15882" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(18 18) rotate(115.115) scale(17.6706 15.2369)">
                                <stop offset="0.0001" stop-color="#398BA9" stop-opacity="0.5"/>
                                <stop offset="0.0002" stop-color="#398BA9" stop-opacity="0"/>
                                <stop offset="1" stop-color="#398BA9" stop-opacity="0.4"/>
                                </radialGradient>
                                </defs>
                            </svg>
                            Planned solar development
                        </p>
                    </div>
                </div>
                <div class="col">
                    <img src="../../../../assets/images/solar-growth-graph.png" />
                </div>
            </div> -->

    <div class="biodiversity-container">
      <div class="col">
        <h4>Biodiversity</h4>
        <p>
          Fortress is committed to the protection and enhancement of the natural
          environment and the ecosystems supporting it.
        </p>
        <p>
          We owns the Meycol Nature Reserve, a 74ha property situated on the
          banks of the Tugela River on the north coast of KwaZulu-Natal.
        </p>
        <p>
          Protecting approximately 37,6 ha of primary ‘critically endangered’
          KwaZulu-Natal coastal belt grassland and it secures 21,1ha of
          untransformed subtropical freshwater swamp forest wetland.
        </p>
      </div>
      <div class="col">
        <img src="../../../../assets/images/biodiversity-img.png" />
      </div>
    </div>
  </div>

  <app-foursight-news [whiteNav]="true"></app-foursight-news>

  <div class="test">
    <div class="row">
      <div class="text-container">
        <h4>2023</h4>
        <h1>Our Impact report</h1>
        <p>
          We strive to better understand what matters - not only with regard to
          our creation and protection of enterprise value - but also with
          respect to how we enhance and protect our societal and environmental
          impact.
        </p>
      </div>
      <div class="btn-container">
        <a
          class="primary-btn"
          [routerLink]="['/investor-relations/investor-documents']"
          [queryParams]="{ document: 'annual-reports' }"
          >View Report</a
        >
      </div>
    </div>
  </div>

  <!-- <app-get-in-touch-banner></app-get-in-touch-banner> -->
</div>
