<div class="bg">
    <app-breadcrumbs grandparentTitle="Home" grandparentLink="/" parentTitle="Our properties" childTitle="New Development"></app-breadcrumbs>
    <app-banner-foursight
        title="New developments for a new world"
        link="/"
        linkText="See more featured developments"
        image="url(https://fortressfundeltbucket.blob.core.windows.net/public-portal-images/4e9336ed-c82e-4c2c-9c0c-f587d995dd77.jpg)"
        imageAlone="https://fortressfundeltbucket.blob.core.windows.net/public-portal-images/4e9336ed-c82e-4c2c-9c0c-f587d995dd77.jpg"
        text="As the leaders in the South African sphere of new developments and logistics parks, we have an abundance of new developments to suit both small- and large-scale business operations. All of these new development schemes enhance businesses: operations are able to run smoothly, logistics become hassle-free, and overall time and costs are saved."
        subTitle =""
        [goHome]=true
    ></app-banner-foursight>
    <app-title-col
        titleGreen="Growth powering"
        titleBlack="logistics real estate developments"
        text="We are the largest owner and developer of core, premium-grade logistics real estate in South Africa, offering innovative real estate solutions that challenge convention."
        buttonText="Speak to us today"
        link="/get-in-touch"
    ></app-title-col>

    <app-footprint></app-footprint>

    <app-solutions-properties></app-solutions-properties>

    <app-stats-properties></app-stats-properties>

    <app-reit-with-a-difference-blocks
        title = "Where We Invest"
        intro="Our diversified real estate portfolio primarily focuses on the logistics and convenience and commuter-based retail sectors in South Africa. We are actively looking for new retail and prime logistics development opportunities."
    desc="Our investment in NEPI Rockcastle provides us with exposure to the high-growth retail sector in Central and Eastern European (CEE) markets. We are selective and intend growing our high-quality logistics portfolio across the CEE region in the coming years. The market is in its development phase with a lot of opportunities for real estate investment for Fortress. As retail competition in the advanced economies of Western Europe is increasingly determined by the ability to successfully support e-commerce via omnichannel, growth in the logistics real estate sector in CEE will continue for decades to come."
        box1icon = "../../../assets/images/schedule-properties-callout.svg"
        box1Title = "Schedule Of Properties"
        box1desc = "Our extensive and diverse state-of-the-art portfolio includes development, industrial, logistics, office, residential and retail properties in South Africa and abroad."
        box1btnText = "Explore Our Properties "
        box1link = "/properties/schedule-of-properties"
        box2icon = "../../../assets/images/properties-callout.svg"
        box2Title = "Our Property Portfolio"
        box2desc = "Our diversified real estate portfolio primarily focuses on the logistics and retail sectors in South Africa, with industrial and office assets complementing our offering. Our investment in NEPI Rockcastle provides us with exposure to high-growth European economies in the Central and Eastern European market."
        box2btnText = "Explore Our Properties "
        box2link = "/properties/property-portfolio"
    ></app-reit-with-a-difference-blocks>

    <app-subscribe-banner></app-subscribe-banner>

    <app-foursight-news></app-foursight-news>
    
    <app-get-in-touch-banner></app-get-in-touch-banner>
</div>