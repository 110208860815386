<div class="bg">
    <app-breadcrumbs grandparentTitle="Home" grandparentLink="/" parentTitle="About Fortress" childTitle="Our Impact" [isLarge]=false></app-breadcrumbs>

    <app-about-two-block-intro
        title="Our Impact"
        subTitle="We are committed to using innovation to reduce our impact on the environments in which we operate. Sustainable and ethical practices are a priority in the way we do business."
        text="At 30 June 2022, we had 18 operational solar PV installations, with another seven under construction. Moreover, seven additional feasibility studies are being conducted and we are in various stages of Eskom approvals on a further seven projects. Our installed capacity was 7,248MWac at 30 June 2022 compared to 4,735MWac at 30 June 2021. We generated 10 773 MWh from our solar PV plants during  FY2022 compared to 5 659MWh in FY2021."
        image="url('../../../assets/images/about-us-intro.webp')"
        video="true"
    ></app-about-two-block-intro>

    <app-power-of-collab></app-power-of-collab>

    <app-our-ambition></app-our-ambition>

    <app-reit-with-a-difference-blocks
        title = "Where We Invest"
        intro="Our diversified real estate portfolio primarily focuses on the logistics and convenience and commuter-based retail sectors in South Africa. We are actively looking for new retail and prime logistics development opportunities."
    desc="Our investment in NEPI Rockcastle provides us with exposure to the high-growth retail sector in Central and Eastern European (CEE) markets. We are selective and intend growing our high-quality logistics portfolio across the CEE region in the coming years. The market is in its development phase with a lot of opportunities for real estate investment for Fortress. As retail competition in the advanced economies of Western Europe is increasingly determined by the ability to successfully support e-commerce via omnichannel, growth in the logistics real estate sector in CEE will continue for decades to come."
        box1Title = "About us"
        box1desc = "We are Fortress Real Estate with a difference. We connect businesses with growth-powering real estate opportunities in state-of-the art logistics parks and convenience and commuter-based retail."
        box1btnText = "Learn more"
        box1link = "/about/about-us"
        box2icon = "../../../assets/images/meet-the-team-callout.svg"
        box2Title = "Meet the team"
        box2desc = "Fortress has a highly regarded and experienced board and management team with extensive property-specific knowledge. We leverage this knowledge, along with our capacity to innovate, to build our Fortress brand and create value for our stakeholders. Our collective skills, experience and resources unlock the value in our properties and investments."
        box2btnText = "Learn more"
        box2link = "/about/meet-the-team"
    ></app-reit-with-a-difference-blocks>

   

    <app-subscribe-banner></app-subscribe-banner>

    <app-foursight-news></app-foursight-news>

    <app-get-in-touch-banner></app-get-in-touch-banner>

</div>