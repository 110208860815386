<div class="banner-container">
  <div class="banner-inner">
    <div class="col-content">
      <div class="col-content-inner">
        <a (click)="back()" class="back-btn">
            <img src="../../../../../assets/icons/icon-arrow-down.png" />
            <p>Back</p>
        </a>
        <p class="subtitle" *ngIf="subTitle">
          <b>{{ subTitle }}</b>
        </p>

        <h2>{{ title }}</h2>
        <p *ngIf="text" class="text" [innerHTML]="text"></p>

        <div class="contactBlock" *ngIf="contacts.length > 0">
          <p class="subtitle"><b>Contact Us</b></p>
          <div class="rowBlock">
            <div class="contact" *ngFor="let contact of contacts">
              <img
                class="pp"
                src="../../../../../assets/images/profilePic.webp"
                alt=""
              />
              <p>
                <b>{{ contact.name }}</b>
              </p>
              <p *ngIf="contact.jobTitle">{{ contact.jobTitle }}</p>
              <div class="conentBlock">
                <a *ngIf="contact.email" [href]="getMail(contact.email)"
                  ><img
                    src="../../../../../assets/icons/icon-mail.svg"
                    alt=""
                  />{{ contact.email }}</a
                >
                <a
                  class="numberClass"
                  *ngIf="contact.cell"
                  (click)="copyNum(contact.cell, $event)"
                  ><img
                    src="../../../../../assets/icons/icon-phone-black.svg"
                    alt=""
                  />{{ contact.cell }}</a
                >
              </div>
            </div>
            <p class="shownum" *ngIf="showNr">Copied</p>
          </div>
        </div>

        <div class="share">
          <p class="subtitle"><b>Share this property</b></p>
          <div class="rowHold">
            <a
              [href]="getWA()"
              data-action="share/whatsapp/share"
              target="_blank"
              ><img src="../../../../../assets/icons/icon-whatsapp.svg" alt=""
            /></a>

            <a [href]="getMailShare()" target="_blank"
              ><img src="../../../../../assets/icons/icon-mail.svg" alt=""
            /></a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-image" [style.background-image]="image"></div>
  </div>
</div>
