<div class="bg">
  <app-breadcrumbs
    grandparentTitle="Home"
    grandparentLink="/"
    parentTitle="Investor Relations"
    childTitle="Shareholder Analysis"
  ></app-breadcrumbs>

  <app-banner-foursight
    title="Shareholder Analysis"
    link=""
    linkText=""
    image="url(../../../assets/images/shareholderAnalysis.webp)"
    imageAlone="../../../assets/images/shareholderAnalysis.webp"
    text="as at 30 June 2023"
    subTitle="Analysis of ordinary shareholders"
  ></app-banner-foursight>

  <div class="container">
    <h2>Fortress Real Estate Investments - A Shares</h2>
    <table>
      <thead>
        <th>Shareholder type</th>
        <th>Number of shareholdings</th>
        <th>% of total shareholdings</th>
        <th>Number of shares</th>
        <th>% of issued capital</th>
      </thead>
      <tbody>
        <tr class="bold bgColor">
          <td>Non public shareholders</td>
          <td>52</td>
          <td>1.04</td>
          <td>31 775 789</td>
          <td>2.67</td>
        </tr>
        <tr>
          <td>Directors, employees and their associates</td>
          <td>51</td>
          <td>1.02</td>
          <td>4 913 793</td>
          <td>0,41</td>
        </tr>
        <tr>
          <td>Treasury</td>
          <td>1</td>
          <td>0,02</td>
          <td>26 861 996</td>
          <td>2.26</td>
        </tr>
        <tr class="bold bgColor">
          <td>Public shareholders</td>
          <td>4 955</td>
          <td>98.96</td>
          <td>1 159 819 383</td>
          <td>97.33</td>
        </tr>
        <tr class="bold">
          <td>Total</td>
          <td>5 007</td>
          <td>100,00</td>
          <td>1 191 595 172</td>
          <td>100,00</td>
        </tr>
      </tbody>
    </table>

    <table>
      <thead>
        <th>Shareholder spread</th>
        <th>Number of shareholdings</th>
        <th>% of total shareholdings</th>
        <th>Number of shares</th>
        <th>% of issued capital</th>
      </thead>
      <tbody>
        <tr>
          <td>1 - 1 000</td>
          <td>2 335</td>
          <td>46.63</td>
          <td>439 901</td>
          <td>0,04</td>
        </tr>
        <tr>
          <td>1 001 - 10 000</td>
          <td>1 431</td>
          <td>28.58</td>
          <td>5 510 689</td>
          <td>0.46</td>
        </tr>
        <tr>
          <td>10 001 - 100 000</td>
          <td>655</td>
          <td>13.08</td>
          <td>21 552 485</td>
          <td>1.81</td>
        </tr>
        <tr>
          <td>100 001 - 1 000 000</td>
          <td>410</td>
          <td>8.19</td>
          <td>136 884 478</td>
          <td>11.49</td>
        </tr>
        <tr>
          <td>Over 1 000 000</td>
          <td>176</td>
          <td>3.52</td>
          <td>1 027 207 619</td>
          <td>86.20</td>
        </tr>
        <tr class="bold bgColor">
          <td>Total</td>
          <td>5007</td>
          <td>100,00</td>
          <td>1 191 595 172</td>
          <td>100,00</td>
        </tr>
      </tbody>
    </table>

    <table class="smallTable">
      <thead>
        <th style="width: 60%">
          Registered shareholders owning 5% or more of the issued shares
        </th>
        <th>Number of shares</th>
        <th>% of issued capital</th>
      </thead>
      <tbody>
        <tr>
          <td>Government Employees Pension Fund</td>
          <td>169 521 464</td>
          <td>14.23</td>
        </tr>
        <tr>
          <td>Coronation Balanced Plus Fund</td>
          <td>116 363 447</td>
          <td>9,77</td>
        </tr>
        <tr class="bold bgColor">
          <td>Total</td>
          <td>285 884 911</td>
          <td>24.00</td>
        </tr>
      </tbody>
    </table>

    <table class="smallTable">
      <thead>
        <th style="width: 60%">
          Beneficial shareholders with a holding of 5% or more of the issued
          share
        </th>
        <th>Number of shares</th>
        <th>% of issued capital</th>
      </thead>
      <tbody>
        <tr>
          <td>Government Employees Pension Fund</td>
          <td>187 612 563</td>
          <td>15.74</td>
        </tr>
        <tr>
          <td>Coronation Fund Managers</td>
          <td>146 171 917</td>
          <td>12.27</td>
        </tr>
        <tr class="bold bgColor">
          <td>Total</td>
          <td>333 784 480</td>
          <td>28.01</td>
        </tr>
      </tbody>
    </table>

    <table class="smallTable">
      <thead>
        <th style="width: 60%">Control of more than 5% of issued shares</th>
        <th>Number of shares</th>
        <th>% of issued capital</th>
      </thead>
      <tbody>
        <tr>
          <td>Coronation Fund Managers</td>
          <td>190 409 390</td>
          <td>15.98</td>
        </tr>
        <tr>
          <td>Public Investment Corporation</td>
          <td>189 947 685</td>
          <td>15.94</td>
        </tr>
        <tr>
          <td>Sesfikile Capital</td>
          <td>81 806 272</td>
          <td>6.87</td>
        </tr>
        <tr>
          <td>Allan Gray</td>
          <td>62 040 726</td>
          <td>5.21</td>
        </tr>
        <tr>
          <td>Catalyst Fund Managers</td>
          <td>59 921 521</td>
          <td>5.03</td>
        </tr>

        <tr class="bold bgColor">
          <td>Total</td>
          <td>584 125 594</td>
          <td>49.03</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="container">
    <h2>Fortress Real Estate Investments - B Shares</h2>
    <table>
      <thead>
        <th>Shareholder Spread</th>
        <th>Number of shareholdings</th>
        <th>% of total shareholdings</th>
        <th>Number of shares</th>
        <th>% of issued capital</th>
      </thead>
      <tbody>
        <tr>
          <td>1 - 1 000</td>
          <td>3 905</td>
          <td>60,6</td>
          <td>413 322</td>
          <td>0,1</td>
        </tr>
        <tr>
          <td>1 001 - 10 000</td>
          <td>1 358</td>
          <td>21,1</td>
          <td>5 173 350</td>
          <td>0,4</td>
        </tr>
        <tr>
          <td>10 001 - 100 000</td>
          <td>616</td>
          <td>9,6</td>
          <td>19 958 688</td>
          <td>1,7</td>
        </tr>
        <tr>
          <td>100 001 - 1 000 000</td>
          <td>390</td>
          <td>6,1</td>
          <td>131 607 353</td>
          <td>11,0</td>
        </tr>
        <tr>
          <td>Over 1 000 000</td>
          <td>176</td>
          <td>2,6</td>
          <td>1 035 648 580</td>
          <td>86,8</td>
        </tr>
        <tr class="bold bgColor">
          <td>Total</td>
          <td>6 445</td>
          <td>100,00</td>
          <td>1 192 801 293</td>
          <td>100,0</td>
        </tr>
      </tbody>
    </table>
    <table class="smallTable">
      <thead>
        <th style="width: 60%">Distribution of shareholders</th>
        <th>Number of shareholdings</th>
        <th>% of total shareholdings</th>
        <th>Number of shares</th>
        <th>% of issued capital</th>
      </thead>
      <tbody>
        <tr>
          <td>Assurance companies</td>
          <td>31</td>
          <td>0,5</td>
          <td>22 613 027</td>
          <td>1,9</td>
        </tr>
        <tr>
          <td>Closed corporations</td>
          <td>41</td>
          <td>0,6</td>
          <td>1 229 960</td>
          <td>0,1</td>
        </tr>
        <tr>
          <td>Collective investment schemes</td>
          <td>343</td>
          <td>5,3</td>
          <td>398 769 834</td>
          <td>33,4</td>
        </tr>
        <tr>
          <td>Control accounts</td>
          <td>4</td>
          <td>0,1</td>
          <td>67</td>
          <td>0,0</td>
        </tr>
        <tr>
          <td>Custodians</td>
          <td>24</td>
          <td>0,4</td>
          <td>5 769 817</td>
          <td>0,5</td>
        </tr>
        <tr>
          <td>Foundations and charitable funds</td>
          <td>53</td>
          <td>0,8</td>
          <td>9 092 894</td>
          <td>0,8</td>
        </tr>
        <tr>
          <td>Hedge funds</td>
          <td>18</td>
          <td>0,3</td>
          <td>55 783 555</td>
          <td>4,7</td>
        </tr>
        <tr>
          <td>Insurance companies</td>
          <td>3</td>
          <td>0,0</td>
          <td>1 400 149</td>
          <td>0,1</td>
        </tr>
        <tr>
          <td>Investment partnerships</td>
          <td>9</td>
          <td>0,1</td>
          <td>75 072</td>
          <td>0,1</td>
        </tr>
        <tr>
          <td>Managed funds</td>
          <td>45</td>
          <td>0,7</td>
          <td>4 123 164</td>
          <td>0,3</td>
        </tr>
        <tr>
          <td>Medical aid funds</td>
          <td>17</td>
          <td>0,3</td>
          <td>12 327 072</td>
          <td>1,0</td>
        </tr>
        <tr>
          <td>Organs of state</td>
          <td>9</td>
          <td>0,1</td>
          <td>261 151 931</td>
          <td>21,9</td>
        </tr>
        <tr>
          <td>Private companies</td>
          <td>141</td>
          <td>2,2</td>
          <td>3 901 908</td>
          <td>0,3</td>
        </tr>
        <tr>
          <td>Public companies</td>
          <td>10</td>
          <td>0,2</td>
          <td>19 153 124</td>
          <td>1,6</td>
        </tr>
        <tr>
          <td>Public entities</td>
          <td>5</td>
          <td>0,1</td>
          <td>742 490</td>
          <td>0,1</td>
        </tr>
        <tr>
          <td>Retail shareholders</td>
          <td>3 832</td>
          <td>59,6</td>
          <td>20 398 603</td>
          <td>1,7</td>
        </tr>
        <tr>
          <td>Retirement benefit funds</td>
          <td>1 516</td>
          <td>23,5</td>
          <td>216 425 892</td>
          <td>18,1</td>
        </tr>
        <tr>
          <td>Scrip lending</td>
          <td>15</td>
          <td>0,2</td>
          <td>119 441 607</td>
          <td>10,0</td>
        </tr>
        <tr>
          <td>Sovereign funds</td>
          <td>3</td>
          <td>0,0</td>
          <td>5 181 088</td>
          <td>0,4</td>
        </tr>
        <tr>
          <td>Stockbrokers and nominees</td>
          <td>20</td>
          <td>0,3</td>
          <td>27 275 586</td>
          <td>2,3</td>
        </tr>
        <tr>
          <td>Trusts</td>
          <td>298</td>
          <td>4,6</td>
          <td>7 941 979</td>
          <td>0,7</td>
        </tr>
        <tr>
          <td>Unclaimed scrip</td>
          <td>8</td>
          <td>0,1</td>
          <td>2 474</td>
          <td>0,0</td>
        </tr>
        <tr class="bold bgColor">
          <td>Total</td>
          <td>6 445</td>
          <td>100,0</td>
          <td>1 192 801 293</td>
          <td>100,0</td>
        </tr>
      </tbody>
    </table>

    <table>
      <thead>
        <th>Shareholder type</th>
        <th>Number of shareholdings</th>
        <th>% of total shareholdings</th>
        <th>Number of shares</th>
        <th>% of issued capital</th>
      </thead>
      <tbody>
        <tr class="bold bgColor">
          <td>Non public shareholders</td>
          <td>56</td>
          <td>0.87</td>
          <td>8 617 081</td>
          <td>0.72</td>
        </tr>
        <tr>
          <td>Directors, employees and their associates</td>
          <td>55</td>
          <td>0,85</td>
          <td>6 352 681</td>
          <td>0.53</td>
        </tr>
        <tr>
          <td>Treasury</td>
          <td>1</td>
          <td>0,02</td>
          <td>2 264 400</td>
          <td>0.19</td>
        </tr>
        <tr class="bold bgColor">
          <td>Public shareholders</td>
          <td>6 389</td>
          <td>99.13</td>
          <td>1 184 184 212</td>
          <td>99.28</td>
        </tr>
        <tr class="bold">
          <td>Total</td>
          <td>6 445</td>
          <td>100,00</td>
          <td>1 192 801 293</td>
          <td>100,00</td>
        </tr>
      </tbody>
    </table>

    <table class="smallTable">
      <thead>
        <th style="width: 60%">
          Registered shareholders owning 5% or more of the issued shares
        </th>
        <th>Number of shares</th>
        <th>% of issued capital</th>
      </thead>
      <tbody>
        <tr>
          <td>Government Employees Pension Fund</td>
          <td>192 493 549</td>
          <td>16.14</td>
        </tr>
        <tr class="bold bgColor">
          <td>Total</td>
          <td>192 493 549</td>
          <td>16.14</td>
        </tr>
      </tbody>
    </table>

    <table class="smallTable">
      <thead>
        <th style="width: 60%">
          Fund managers holding more than 5% of issued shares
        </th>
        <th>Number of shares</th>
        <th>% of issued capital</th>
      </thead>
      <tbody>
        <tr>
          <td>Public Investment Corporation</td>
          <td>212 705 535</td>
          <td>17,83</td>
        </tr>
        <tr>
          <td>Meago Asset Management</td>
          <td>81 702 074</td>
          <td>6,85</td>
        </tr>
        <tr>
          <td>Sesfikle Capital</td>
          <td>74 923 330</td>
          <td>6,28</td>
        </tr>
        <tr>
          <td>Coronation Asset Management</td>
          <td>71 695 200</td>
          <td>6,01</td>
        </tr>
        <tr>
          <td>Catalyst Fund Managers</td>
          <td>69 709 174</td>
          <td>5,84</td>
        </tr>
        <tr class="bold bgColor">
            <td>Total</td>
            <td>510 735 313</td>
            <td>42.81</td>
          </tr>
      </tbody>
    </table>
    <table class="smallTable">
        <thead>
          <th style="width: 60%">
            Beneficial shareholders with a holding greater that 5% of the issued shares
          </th>
          <th>Number of shares</th>
          <th>% of issued capital</th>
        </thead>
        <tbody>
          <tr>
            <td>Public Investment Corporation</td>
            <td>261 151 931</td>
            <td>21.89</td>
          </tr>
          <tr>
            <td>Coronation Asset Management</td>
            <td>71 695 200</td>
            <td>6.01</td>
          </tr>
          <tr>
            <td>Eskom Pension and Provident Fund</td>
            <td>62 089 605</td>
            <td>5.21</td>
          </tr>
          <tr class="bold bgColor">
              <td>Total</td>
              <td>294 936 736</td>
              <td>33,11</td>
            </tr>
        </tbody>
      </table>
  
    <p>* A subsidiary of Fortress Real Estate Investments</p>
  </div>

  <app-get-in-touch-banner></app-get-in-touch-banner>
</div>
