import { Component, OnInit } from '@angular/core';
import { FortressPropertyService } from "../../../services/fortress-property.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

export interface props {
  askingPrice: any
  buildingCode: string
  city: string
  country: string
  description: any
  erfNo: any
  latitude:number
  buildingContactableList:any
  buildingFeatures: any
  latitudeString: string
  legalEntity: any
  lessorRegNo: any
  location: any
  longitude: number
  longitudeString: string
  marketProperty: boolean
  modified: boolean
  buildingImages:any
  postalCode: any
  priceOnRequest: boolean
  propertyCode: string
  totalUnits:number
  id:number
  buildingName: string
  province: string
  saleStatus: any
  saleStatusInt: any
  saleStatusString: any
  salesMarketingDescription: any
  sector: string
  sectorInt: number
  status: string
  statusInt: number
  streetAddress: string
  streetNumber: any
  subCategory: number
  subCategoryInt: number
  suburb: string
  buildingGla: number
  updatePropertyName: boolean
  vatNo: any
  videoUrl: string
  virtualTourUrl: string
  webUrl: string
  youTubeUrl: any
  _originalPropertyName: string
  frontPageImage:string
  imageBucketUrl:string
  units:any
  buildingId:any
}

@Component({
  selector: 'app-individual-property',
  templateUrl: './individual-property.component.html',
  styleUrls: ['./individual-property.component.scss']
})
export class IndividualPropertyComponent implements OnInit {
  allProp: props[] =[];
  currentProp = <props>{};
  message:boolean=false;
  showUnits:boolean=false;
  
  units:any[]=[]
  messageSent:boolean=false;
  id: string = '';
  doneLoading:boolean= false;
  profileForm = new FormGroup({
    firstName: new FormControl('', Validators.required),
    contactEmail: new FormControl(''),
    contactPhone: new FormControl(''),
    notes: new FormControl(''),
  });
  
  constructor(
    private http: HttpClient, 
    private property: FortressPropertyService, 
    private spinner: NgxSpinnerService, 
    private route: ActivatedRoute,
  ) {
   }

  ngOnInit(): void {
    
    this.spinner.show();
    this.route.queryParams.subscribe(params => {
      this.id = params.property;
    });

    this.property.getProperties().subscribe(result => {
      //console.log(result);
      this.allProp = result.filter(prop => {
        // return prop.status !== "Development"
        return true
      }).sort((a, b) => b.buildingGla - a.buildingGla);
      
      for (var i = 0; i < this.allProp.length; i++){
        if (this.allProp[i].id ===  parseInt(this.id)){
          this.currentProp = this.allProp[i];
          this.doneLoading = true;
          break;
        }
      }
      this.spinner.hide();
    }, 
    err => {
       // console.log(err);
    })


    this.property.getUnits(+this.id).subscribe(result1 =>{
     // console.log(result1);
      if (result1.length>0){
        this.showUnits = true;
        this.units = result1;
      }
      
    })
  }

  getImage(bucket:string,img:string){
    if ((typeof bucket != 'undefined') && (typeof img != 'undefined')) {
      if ((img === null)||(bucket === null)){
        return "url(../../../../assets/images/no-prop-image.webp)";
      }else{
        img = img.replace(/\s/g, "%20");
        return "url("+bucket+"/"+img+")";
      }
    }else{
      return ''
    }
  }

  getImageSrc(bucket:string,img:string){
    if ((typeof bucket != 'undefined') && (typeof img != 'undefined')) {
      if ((img === null)||(bucket === null)){
        return "../../../../assets/images/no-prop-image.webp";
      }else{
        img = img.replace(/\s/g, "%20");
        return bucket+"/"+img;
      }
    }else{
      return ''
    }
  }

  getContact(contacts:Array<any>){
   // console.log(contacts);
    if (contacts === null){
      return '';
    }else{
      if (contacts[0].cell!=null){
        var hold = contacts[0].cell.replace(/\s/g, "");
        return "tel:"+hold;
      }else{
        return "";
      }
    }
  }

  onSubmit() {
    if (this.profileForm) {
      var hold = 'Contact Number: ' + this.profileForm.value['contactPhone'] + " Notes: " + this.profileForm.value['notes'];
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      this.http.post('https://formspree.io/mbjwaygo',
        { name: this.profileForm.value['firstName'], replyto: this.profileForm.value['contactEmail'], message: hold},
        { 'headers': headers }).subscribe(
          (          response: any) => {
                if (response.ok){
                  this.messageSent=true;
                  this.message = true;
                }else{
                  this.messageSent=true;
                  this.message = false;
                }
          }
        );
    }
  }
}